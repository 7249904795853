<script setup lang="ts">
import { toRef, onMounted, onUnmounted } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { FloatingWidgetBoxProps, FloatingWidgetBoxSlots } from 'web/src/modules/sportline/submodules/widgets/types';

import { useFloatingWidgetBox } from './useFloatingWidgetBox';

const props = defineProps<FloatingWidgetBoxProps>();
defineSlots<FloatingWidgetBoxSlots>();

const isFloatingModeEnabled = toRef(() => props.isFloatingModeEnabled);

const {
  wrapper,
  isPositionFixed,
  closeFrame,
  addScrollListener,
  removeScrollListener,
} = useFloatingWidgetBox({ isFloatingModeEnabled });

onMounted(addScrollListener);
onUnmounted(removeScrollListener);
</script>

<template>
  <div v-auto-id="'FloatingWidgetBox'"
    ref="wrapper"
    :class="{
      [$style['floating-widget-box-wrapper']]: true,
      [$style['full-size-box']]: isFullSize && !isFlexSize,
      [$style['flex-size-box']]: isFlexSize,
    }"
  >
    <div
      :class="{
        [$style['position-holder']]: true,
        [$style['position-holder--fixed']]: isPositionFixed,
        [$style['full-size-box']]: !isPositionFixed && !isFlexSize,
        [$style['flex-size-box']]: !isPositionFixed && isFlexSize,
      }"
    >
      <div
        :class="{
          [$style['position-holder__column']]: true,
          [$style['position-holder__column--fixed']]: isPositionFixed,
          [$style['full-size-box']]: !isPositionFixed && !isFlexSize,
          [$style['flex-size-box']]: !isPositionFixed && isFlexSize,
        }"
      >
        <div
          :class="{
            [$style['floating-widget-box']]: true,
            [$style['floating-widget-box--fixed']]: isPositionFixed,
            [$style['fixed-size-box']]: isPositionFixed,
            [$style['full-size-box']]: !isPositionFixed && !isFlexSize,
            [$style['flex-size-box']]: !isPositionFixed && isFlexSize,
            [$style['stream-size-box']]: !isPositionFixed && isStream,
          }"
        >
          <slot :is-floating="isPositionFixed" />
          <div
            v-if="isPositionFixed"
            :class="$style['close-icon__holder']"
            @click="closeFrame"
          >
            <VIcon
              v-if="isPositionFixed"
              :name="IconName.CROSS"
              :size="IconSize.SIZE_12"
              :class="$style['close-icon']"
            />
          </div>
        </div>

        <div :class="$style['position-holder__aside']" />
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  $smallWidthDesktop: 1024px;
  $maxWidthDesktop: 1440px;
  $desktopWidth: 285px;
  $desktopHeight: 176px;
  $phoneWidth: 179px;
  $phoneHeight: 110px;

  .full-size-box {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  .flex-size-box {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }

  .fixed-size-box {
    @if $isDesktop {
      width: $desktopWidth;
      height: $desktopHeight;
    } @else {
      width: $phoneWidth;
      height: $phoneHeight;
    }
  }

  .stream-size-box {
    @if not $isDesktop {
      height: 200px;
    }
  }

  .floating-widget-box-wrapper {
    position: relative;
  }

  .floating-widget-box {
    position: relative;
    overflow: hidden;
    pointer-events: initial;
    background-color: var(--Layer1);
    border-radius: if($isDesktop, inherit, unset);

    &--fixed {
      margin: 0;
      filter: var(--FloatingTopBarShadowFilter);
    }

    &--stream {
      @if not $isDesktop {
        height: 200px;
      }
    }
  }

  .position-holder {
    @include z-index('absolute-item');

    top: 0;
    left: 0;
    transition: bottom 0.3s ease-out, left 0.3s ease-out, top 0.3s ease-out, right 0.3s ease-out;

    &--fixed {
      position: fixed;
      width: 100%;
      pointer-events: none;

      @if $isDesktop {
        top: calc($headerHeightDesktopSmall + var(--sportline-mini-board-height));

        @include screen-desktop-large-min {
          top: calc($headerHeightDesktopBig + var(--sportline-mini-board-height));
        }
      } @else {
        top: calc(var(--sportline-mini-board-height) + var(--StatusbarHeight) + var(--sportline-header-height));
      }
    }

    &__column {
      position: relative;

      &--fixed {
        display: flex;
        justify-content: flex-end;

        @if $isDesktop {
          min-width: $smallWidthDesktop;
          max-width: $maxWidthDesktop;
          margin: 0 auto;
        }
      }
    }

    &__aside {
      width: if($isDesktop, $desktopRightSidebarWidth + $scrollWidth * 2, 0);
    }
  }

  .close-icon {
    pointer-events: initial;

    &__holder {
      @include z-index('absolute-item');

      position: absolute;
      top: 2px;
      right: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      color: var(--White);
      cursor: pointer;
      background: rgba(0, 0, 0, 0.5);
      border: none;
      border-radius: 50%;
    }
  }
}
</style>
